/* eslint-disable */
import * as types from "./graphql";
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query AgencyMin($slug: String!) {\n    agency(slug: $slug) {\n      name\n      logoUrl\n      id\n    }\n  }\n":
    types.AgencyMinDocument,
  "\n  fragment AgencyCardData on Agency {\n    id\n    slug\n    name\n    logoUrl\n    sponsored\n    externalId\n    uuid\n    services (first: 3) {\n      totalCount\n      nodes {\n        id\n        expertise {\n          name\n        }\n      }\n    }\n    awards {\n      totalCount\n    }\n    reviews(first: 30) {\n      averageRating\n      totalCount\n      nodes {\n        id\n        recommended\n        createdAt\n        isRecentlyRecommended @client\n      }\n    }\n  }\n":
    types.AgencyCardDataFragmentDoc,
  "\n  query SimilarAgencies ($agencySlug: String!) {\n    agency(slug: $agencySlug) {\n      id\n      competitors (first: 3) {\n        nodes {\n          id\n          ...AgencyCardData\n        }\n      }\n    }\n  }\n":
    types.SimilarAgenciesDocument,
  "\n  query TopPaidAgencies($host: String!, $locale: String!, $path: String!) {\n    paidAgencies: page(host: $host, locale: $locale, path: $path) {\n      pagesAgencies(first: 3, filter:  {\n         sections: [PAID]\n      }) {\n        nodes {\n          section\n          agency {\n            id\n            ...AgencyCardData\n          }\n        }\n      }\n    }\n    organicAgencies: page(host: $host, locale: $locale, path: $path) {\n      pagesAgencies(first: 3, filter:  {\n         sections: [ORGANIC]\n      }) {\n        nodes {\n          section\n          agency {\n            id\n            ...AgencyCardData\n          }\n        }\n      }\n    }\n  }\n":
    types.TopPaidAgenciesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query AgencyMin($slug: String!) {\n    agency(slug: $slug) {\n      name\n      logoUrl\n      id\n    }\n  }\n",
): (typeof documents)["\n  query AgencyMin($slug: String!) {\n    agency(slug: $slug) {\n      name\n      logoUrl\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  fragment AgencyCardData on Agency {\n    id\n    slug\n    name\n    logoUrl\n    sponsored\n    externalId\n    uuid\n    services (first: 3) {\n      totalCount\n      nodes {\n        id\n        expertise {\n          name\n        }\n      }\n    }\n    awards {\n      totalCount\n    }\n    reviews(first: 30) {\n      averageRating\n      totalCount\n      nodes {\n        id\n        recommended\n        createdAt\n        isRecentlyRecommended @client\n      }\n    }\n  }\n",
): (typeof documents)["\n  fragment AgencyCardData on Agency {\n    id\n    slug\n    name\n    logoUrl\n    sponsored\n    externalId\n    uuid\n    services (first: 3) {\n      totalCount\n      nodes {\n        id\n        expertise {\n          name\n        }\n      }\n    }\n    awards {\n      totalCount\n    }\n    reviews(first: 30) {\n      averageRating\n      totalCount\n      nodes {\n        id\n        recommended\n        createdAt\n        isRecentlyRecommended @client\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query SimilarAgencies ($agencySlug: String!) {\n    agency(slug: $agencySlug) {\n      id\n      competitors (first: 3) {\n        nodes {\n          id\n          ...AgencyCardData\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query SimilarAgencies ($agencySlug: String!) {\n    agency(slug: $agencySlug) {\n      id\n      competitors (first: 3) {\n        nodes {\n          id\n          ...AgencyCardData\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query TopPaidAgencies($host: String!, $locale: String!, $path: String!) {\n    paidAgencies: page(host: $host, locale: $locale, path: $path) {\n      pagesAgencies(first: 3, filter:  {\n         sections: [PAID]\n      }) {\n        nodes {\n          section\n          agency {\n            id\n            ...AgencyCardData\n          }\n        }\n      }\n    }\n    organicAgencies: page(host: $host, locale: $locale, path: $path) {\n      pagesAgencies(first: 3, filter:  {\n         sections: [ORGANIC]\n      }) {\n        nodes {\n          section\n          agency {\n            id\n            ...AgencyCardData\n          }\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query TopPaidAgencies($host: String!, $locale: String!, $path: String!) {\n    paidAgencies: page(host: $host, locale: $locale, path: $path) {\n      pagesAgencies(first: 3, filter:  {\n         sections: [PAID]\n      }) {\n        nodes {\n          section\n          agency {\n            id\n            ...AgencyCardData\n          }\n        }\n      }\n    }\n    organicAgencies: page(host: $host, locale: $locale, path: $path) {\n      pagesAgencies(first: 3, filter:  {\n         sections: [ORGANIC]\n      }) {\n        nodes {\n          section\n          agency {\n            id\n            ...AgencyCardData\n          }\n        }\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
