// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
  Range: { input: any[]; output: any[] };
};

export type Agency = Node & {
  __typename?: "Agency";
  awards: AwardConnection;
  competitors?: Maybe<AgencyConnection>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  foundedAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  id: Scalars["ID"]["output"];
  languages?: Maybe<Array<Scalars["String"]["output"]>>;
  linkedinId?: Maybe<Scalars["String"]["output"]>;
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  mainOffice?: Maybe<Office>;
  name: Scalars["String"]["output"];
  offices: OfficeConnection;
  remotely?: Maybe<Scalars["Boolean"]["output"]>;
  reviews: ReviewConnection;
  sectors: SectorConnection;
  services: ServiceConnection;
  slug?: Maybe<Scalars["String"]["output"]>;
  sponsored?: Maybe<Scalars["Boolean"]["output"]>;
  story?: Maybe<Scalars["String"]["output"]>;
  tagline?: Maybe<Scalars["String"]["output"]>;
  teamSize?: Maybe<Scalars["Int"]["output"]>;
  turnover?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  uuid: Scalars["String"]["output"];
  verified?: Maybe<Scalars["Boolean"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type AgencyAwardsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AgencyCompetitorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AgencyOfficesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AgencyReviewsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ReviewFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AgencySectorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AgencyServicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Agency. */
export type AgencyConnection = Node & {
  __typename?: "AgencyConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Agency>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type AgencyEdge = Node & {
  __typename?: "AgencyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Agency>;
};

export type Award = Node & {
  __typename?: "Award";
  agency: Agency;
  category?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  position?: Maybe<Scalars["Int"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  uuid: Scalars["String"]["output"];
  wonAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  work?: Maybe<Work>;
};

/** The connection type for Award. */
export type AwardConnection = Node & {
  __typename?: "AwardConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AwardEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Award>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type AwardEdge = Node & {
  __typename?: "AwardEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Award>;
};

export type Company = Node & {
  __typename?: "Company";
  address?: Maybe<Scalars["String"]["output"]>;
  coordinates?: Maybe<Scalars["JSON"]["output"]>;
  country?: Maybe<Location>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  googlePlaceId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locality?: Maybe<Location>;
  marketSize?: Maybe<Scalars["String"]["output"]>;
  marketType?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  sector?: Maybe<Sector>;
  size?: Maybe<Scalars["Int"]["output"]>;
  turnover?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  website?: Maybe<Scalars["String"]["output"]>;
};

export type Domain = Node & {
  __typename?: "Domain";
  availableLocales: Array<Locale>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  host: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  iso31661?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Location>;
  mainLocale?: Maybe<Locale>;
  robots?: Maybe<Scalars["String"]["output"]>;
  sitemapStaticPaths?: Maybe<Scalars["String"]["output"]>;
  sitemapStatics?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type Expertise = NamedTopic &
  Node & {
    __typename?: "Expertise";
    category?: Maybe<Scalars["String"]["output"]>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    id: Scalars["ID"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
    uuid: Scalars["String"]["output"];
  };

export type Locale = NamedTopic &
  Node & {
    __typename?: "Locale";
    code: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    main: Scalars["Boolean"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
  };

export type Location = NamedTopic &
  Node & {
    __typename?: "Location";
    address?: Maybe<Scalars["String"]["output"]>;
    country?: Maybe<Location>;
    countryIso31661?: Maybe<Scalars["String"]["output"]>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    currency?: Maybe<Scalars["String"]["output"]>;
    famous?: Maybe<Scalars["Boolean"]["output"]>;
    frPrefix?: Maybe<Scalars["String"]["output"]>;
    geometry?: Maybe<Scalars["JSON"]["output"]>;
    googlePlaceId?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    iso31661?: Maybe<Scalars["String"]["output"]>;
    iso31662?: Maybe<Scalars["String"]["output"]>;
    level: Scalars["String"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    simpleAddress?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
    superLocations: LocationConnection;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type LocationSuperLocationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Location. */
export type LocationConnection = Node & {
  __typename?: "LocationConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Location>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type LocationEdge = Node & {
  __typename?: "LocationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Location>;
};

export type Mutation = Node & {
  __typename?: "Mutation";
  /** ID of the object. */
  id: Scalars["ID"]["output"];
};

export type NamedTopic = {
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars["ID"]["output"];
};

export type Office = Node & {
  __typename?: "Office";
  address?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locality?: Maybe<Location>;
};

/** The connection type for Office. */
export type OfficeConnection = Node & {
  __typename?: "OfficeConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OfficeEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Office>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type OfficeEdge = Node & {
  __typename?: "OfficeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Office>;
};

/** pages agency order by enum */
export enum OrderDirection {
  /** ascendent */
  Asc = "ASC",
  /** descendent */
  Desc = "DESC",
}

export type Page = Node & {
  __typename?: "Page";
  baselineValue?: Maybe<Scalars["Float"]["output"]>;
  briefingOptions?: Maybe<Scalars["JSON"]["output"]>;
  canonicalPage?: Maybe<Page>;
  cluster?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  description?: Maybe<Scalars["JSON"]["output"]>;
  domain: Domain;
  faq?: Maybe<Scalars["JSON"]["output"]>;
  frontendType?: Maybe<Scalars["String"]["output"]>;
  httpStatus?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  indexable: Scalars["Boolean"]["output"];
  locale: Locale;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  obfuscateInternals: Scalars["Boolean"]["output"];
  pageOptions?: Maybe<Scalars["JSON"]["output"]>;
  pagesAgencies: PagesAgencyConnection;
  path: Scalars["String"]["output"];
  redirectionPage?: Maybe<Page>;
  strictTopics: TopicConnection;
  title?: Maybe<Scalars["JSON"]["output"]>;
  topics: TopicConnection;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  url: Scalars["String"]["output"];
};

export type PagePagesAgenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<PagesAgencyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<PagesAgencyOrder>;
};

export type PageStrictTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Page. */
export type PageConnection = Node & {
  __typename?: "PageConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PageEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PageEdge = Node & {
  __typename?: "PageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Page>;
};

export type PageFilter = {
  host: Scalars["String"]["input"];
  locale: Scalars["ID"]["input"];
  /** usually a locale plus some locations, expertise, skill */
  topics?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PagesAgency = Node & {
  __typename?: "PagesAgency";
  activated?: Maybe<Scalars["Boolean"]["output"]>;
  agency: Agency;
  agencyCardMetadata?: Maybe<Scalars["JSON"]["output"]>;
  budget?: Maybe<Scalars["Range"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  obfuscated?: Maybe<Scalars["Boolean"]["output"]>;
  page: Page;
  position?: Maybe<Scalars["Int"]["output"]>;
  relevanceScore?: Maybe<Scalars["Float"]["output"]>;
  sameCountryAsPage?: Maybe<Scalars["Boolean"]["output"]>;
  section: PagesAgencySection;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  worksCount?: Maybe<Scalars["Int"]["output"]>;
};

/** The connection type for PagesAgency. */
export type PagesAgencyConnection = Node & {
  __typename?: "PagesAgencyConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PagesAgencyEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PagesAgency>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PagesAgencyEdge = Node & {
  __typename?: "PagesAgencyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<PagesAgency>;
};

export type PagesAgencyFilter = {
  awardsCounts?: InputMaybe<Array<Scalars["Range"]["input"]>>;
  budgets?: InputMaybe<Array<Scalars["Range"]["input"]>>;
  ratings?: InputMaybe<Array<Scalars["Range"]["input"]>>;
  reviewsCounts?: InputMaybe<Array<Scalars["Range"]["input"]>>;
  sections?: InputMaybe<Array<PagesAgencySection>>;
  teamSizes?: InputMaybe<Array<Scalars["Range"]["input"]>>;
  topics?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  worksCounts?: InputMaybe<Array<Scalars["Range"]["input"]>>;
};

export type PagesAgencyOrder = {
  by?: PagesAgencyOrderBy;
  direction?: OrderDirection;
};

/** pages agency order by enum */
export enum PagesAgencyOrderBy {
  /** agency reviews score */
  AgenciesReviewsScore = "AGENCIES_REVIEWS_SCORE",
  /** agency size */
  AgenciesSize = "AGENCIES_SIZE",
  /** position */
  Position = "POSITION",
  /** section */
  Section = "SECTION",
  /** works count */
  WorksCount = "WORKS_COUNT",
}

/** Pages agency section enum */
export enum PagesAgencySection {
  /** manual */
  Manual = "MANUAL",
  /** organic */
  Organic = "ORGANIC",
  /** paid */
  Paid = "PAID",
}

export type Query = Node & {
  __typename?: "Query";
  agency: Agency;
  domain: Domain;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  page: Page;
  searchPages: PageConnection;
  searchTopics: SearchTopicConnection;
};

export type QueryAgencyArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryDomainArgs = {
  host: Scalars["String"]["input"];
};

export type QueryNodeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNodesArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type QueryPageArgs = {
  host: Scalars["String"]["input"];
  locale: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
};

export type QuerySearchPagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter: PageFilter;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuerySearchTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  pageFilter?: InputMaybe<PageFilter>;
  searchText: Scalars["String"]["input"];
  types: Array<TopicExternalType>;
};

export type Review = Node & {
  __typename?: "Review";
  agency: Agency;
  companySize?: Maybe<Scalars["Range"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  descriptionCons?: Maybe<Scalars["String"]["output"]>;
  descriptionPros?: Maybe<Scalars["String"]["output"]>;
  descriptionSummary?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isRecentlyRecommended: Scalars["Boolean"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  onBehalf?: Maybe<Scalars["Boolean"]["output"]>;
  rating?: Maybe<Scalars["Float"]["output"]>;
  ratingBudget?: Maybe<Scalars["Float"]["output"]>;
  ratingCollaboration?: Maybe<Scalars["Float"]["output"]>;
  ratingQuality?: Maybe<Scalars["Float"]["output"]>;
  ratingSchedule?: Maybe<Scalars["Float"]["output"]>;
  recommended?: Maybe<Scalars["Boolean"]["output"]>;
  reply?: Maybe<Scalars["String"]["output"]>;
  sector?: Maybe<Sector>;
  service?: Maybe<Service>;
  slug: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  userCompanyName?: Maybe<Scalars["String"]["output"]>;
  userFullName?: Maybe<Scalars["String"]["output"]>;
  userJobTitle?: Maybe<Scalars["String"]["output"]>;
  userPictureUrl?: Maybe<Scalars["String"]["output"]>;
  uuid: Scalars["String"]["output"];
};

/** The connection type for Review. */
export type ReviewConnection = Node & {
  __typename?: "ReviewConnection";
  averageRating?: Maybe<Scalars["Float"]["output"]>;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReviewEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Review>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** used to sort, prefering those with more reviews when same average */
  score?: Maybe<Scalars["Float"]["output"]>;
  totalCount: Scalars["Int"]["output"];
  totalRating?: Maybe<Scalars["Float"]["output"]>;
};

/** An edge in a connection. */
export type ReviewEdge = Node & {
  __typename?: "ReviewEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Review>;
};

export type ReviewFilter = {
  recommended?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The connection type for Topic. */
export type SearchTopicConnection = Node & {
  __typename?: "SearchTopicConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchTopicEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Topic>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type SearchTopicEdge = Node & {
  __typename?: "SearchTopicEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Topic>;
  page?: Maybe<Page>;
};

export type Sector = NamedTopic &
  Node & {
    __typename?: "Sector";
    id: Scalars["ID"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
  };

/** The connection type for Sector. */
export type SectorConnection = Node & {
  __typename?: "SectorConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SectorEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Sector>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type SectorEdge = Node & {
  __typename?: "SectorEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Sector>;
};

export type Service = Node & {
  __typename?: "Service";
  agency: Agency;
  budget?: Maybe<Scalars["Range"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  expertise: Expertise;
  id: Scalars["ID"]["output"];
  position?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  uuid: Scalars["String"]["output"];
  works: WorkConnection;
};

export type ServiceWorksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Service. */
export type ServiceConnection = Node & {
  __typename?: "ServiceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ServiceEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Service>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalBudget?: Maybe<Scalars["Range"]["output"]>;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ServiceEdge = Node & {
  __typename?: "ServiceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Service>;
};

export type Skill = NamedTopic &
  Node & {
    __typename?: "Skill";
    createdAt: Scalars["ISO8601DateTime"]["output"];
    id: Scalars["ID"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
    uuid?: Maybe<Scalars["String"]["output"]>;
  };

export type Topic =
  | Expertise
  | Locale
  | Location
  | Sector
  | Skill
  | UnknownTopic;

/** The connection type for Topic. */
export type TopicConnection = Node & {
  __typename?: "TopicConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TopicEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Topic>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TopicEdge = Node & {
  __typename?: "TopicEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Topic>;
};

/** topic's external_type enum */
export enum TopicExternalType {
  /** audience */
  Audience = "AUDIENCE",
  /** category */
  Category = "CATEGORY",
  /** certification */
  Certification = "CERTIFICATION",
  /** custom */
  Custom = "CUSTOM",
  /** expertise */
  Expertise = "EXPERTISE",
  /** feature */
  Feature = "FEATURE",
  /** framework */
  Framework = "FRAMEWORK",
  /** industry */
  Industry = "INDUSTRY",
  /** locale */
  Locale = "LOCALE",
  /** location */
  Location = "LOCATION",
  /** platform */
  Platform = "PLATFORM",
  /** skill */
  Skill = "SKILL",
}

export type UnknownTopic = NamedTopic &
  Node & {
    __typename?: "UnknownTopic";
    id: Scalars["ID"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
  };

export type Work = Node & {
  __typename?: "Work";
  agency: Agency;
  budget?: Maybe<Scalars["Int"]["output"]>;
  challenges?: Maybe<Scalars["String"]["output"]>;
  company: Company;
  concretization?: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  creativity?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  impacts?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
  releasedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  solutions?: Maybe<Scalars["String"]["output"]>;
  specialization?: Maybe<Scalars["Int"]["output"]>;
  startDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  uuid: Scalars["String"]["output"];
};

/** The connection type for Work. */
export type WorkConnection = Node & {
  __typename?: "WorkConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkEdge>>>;
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Work>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type WorkEdge = Node & {
  __typename?: "WorkEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** ID of the object. */
  id: Scalars["ID"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Work>;
};

export type AgencyMinQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;

export type AgencyMinQuery = {
  __typename?: "Query";
  agency: {
    __typename?: "Agency";
    name: string;
    logoUrl?: string | null;
    id: string;
  };
};

export type AgencyCardDataFragment = {
  __typename?: "Agency";
  id: string;
  slug?: string | null;
  name: string;
  logoUrl?: string | null;
  sponsored?: boolean | null;
  externalId?: string | null;
  uuid: string;
  services: {
    __typename?: "ServiceConnection";
    totalCount: number;
    nodes?: Array<{
      __typename?: "Service";
      id: string;
      expertise: { __typename?: "Expertise"; name?: string | null };
    } | null> | null;
  };
  awards: { __typename?: "AwardConnection"; totalCount: number };
  reviews: {
    __typename?: "ReviewConnection";
    averageRating?: number | null;
    totalCount: number;
    nodes?: Array<{
      __typename?: "Review";
      id: string;
      recommended?: boolean | null;
      createdAt: any;
      isRecentlyRecommended: boolean;
    } | null> | null;
  };
};

export type SimilarAgenciesQueryVariables = Exact<{
  agencySlug: Scalars["String"]["input"];
}>;

export type SimilarAgenciesQuery = {
  __typename?: "Query";
  agency: {
    __typename?: "Agency";
    id: string;
    competitors?: {
      __typename?: "AgencyConnection";
      nodes?: Array<{
        __typename?: "Agency";
        id: string;
        slug?: string | null;
        name: string;
        logoUrl?: string | null;
        sponsored?: boolean | null;
        externalId?: string | null;
        uuid: string;
        services: {
          __typename?: "ServiceConnection";
          totalCount: number;
          nodes?: Array<{
            __typename?: "Service";
            id: string;
            expertise: { __typename?: "Expertise"; name?: string | null };
          } | null> | null;
        };
        awards: { __typename?: "AwardConnection"; totalCount: number };
        reviews: {
          __typename?: "ReviewConnection";
          averageRating?: number | null;
          totalCount: number;
          nodes?: Array<{
            __typename?: "Review";
            id: string;
            recommended?: boolean | null;
            createdAt: any;
            isRecentlyRecommended: boolean;
          } | null> | null;
        };
      } | null> | null;
    } | null;
  };
};

export type TopPaidAgenciesQueryVariables = Exact<{
  host: Scalars["String"]["input"];
  locale: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
}>;

export type TopPaidAgenciesQuery = {
  __typename?: "Query";
  paidAgencies: {
    __typename?: "Page";
    pagesAgencies: {
      __typename?: "PagesAgencyConnection";
      nodes?: Array<{
        __typename?: "PagesAgency";
        section: PagesAgencySection;
        agency: {
          __typename?: "Agency";
          id: string;
          slug?: string | null;
          name: string;
          logoUrl?: string | null;
          sponsored?: boolean | null;
          externalId?: string | null;
          uuid: string;
          services: {
            __typename?: "ServiceConnection";
            totalCount: number;
            nodes?: Array<{
              __typename?: "Service";
              id: string;
              expertise: { __typename?: "Expertise"; name?: string | null };
            } | null> | null;
          };
          awards: { __typename?: "AwardConnection"; totalCount: number };
          reviews: {
            __typename?: "ReviewConnection";
            averageRating?: number | null;
            totalCount: number;
            nodes?: Array<{
              __typename?: "Review";
              id: string;
              recommended?: boolean | null;
              createdAt: any;
              isRecentlyRecommended: boolean;
            } | null> | null;
          };
        };
      } | null> | null;
    };
  };
  organicAgencies: {
    __typename?: "Page";
    pagesAgencies: {
      __typename?: "PagesAgencyConnection";
      nodes?: Array<{
        __typename?: "PagesAgency";
        section: PagesAgencySection;
        agency: {
          __typename?: "Agency";
          id: string;
          slug?: string | null;
          name: string;
          logoUrl?: string | null;
          sponsored?: boolean | null;
          externalId?: string | null;
          uuid: string;
          services: {
            __typename?: "ServiceConnection";
            totalCount: number;
            nodes?: Array<{
              __typename?: "Service";
              id: string;
              expertise: { __typename?: "Expertise"; name?: string | null };
            } | null> | null;
          };
          awards: { __typename?: "AwardConnection"; totalCount: number };
          reviews: {
            __typename?: "ReviewConnection";
            averageRating?: number | null;
            totalCount: number;
            nodes?: Array<{
              __typename?: "Review";
              id: string;
              recommended?: boolean | null;
              createdAt: any;
              isRecentlyRecommended: boolean;
            } | null> | null;
          };
        };
      } | null> | null;
    };
  };
};

export const AgencyCardDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AgencyCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Agency" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "logoUrl" } },
          { kind: "Field", name: { kind: "Name", value: "sponsored" } },
          { kind: "Field", name: { kind: "Name", value: "externalId" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "services" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "3" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expertise" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "awards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviews" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "30" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "recommended" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRecentlyRecommended" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgencyCardDataFragment, unknown>;
export const AgencyMinDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AgencyMin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "agency" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "logoUrl" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgencyMinQuery, AgencyMinQueryVariables>;
export const SimilarAgenciesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SimilarAgencies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "agencySlug" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "agency" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "agencySlug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "competitors" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "3" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "AgencyCardData" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AgencyCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Agency" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "logoUrl" } },
          { kind: "Field", name: { kind: "Name", value: "sponsored" } },
          { kind: "Field", name: { kind: "Name", value: "externalId" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "services" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "3" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expertise" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "awards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviews" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "30" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "recommended" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRecentlyRecommended" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SimilarAgenciesQuery,
  SimilarAgenciesQueryVariables
>;
export const TopPaidAgenciesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TopPaidAgencies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "host" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "locale" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "path" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "paidAgencies" },
            name: { kind: "Name", value: "page" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "host" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "host" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "locale" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "path" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagesAgencies" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sections" },
                            value: {
                              kind: "ListValue",
                              values: [{ kind: "EnumValue", value: "PAID" }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "section" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "agency" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "AgencyCardData",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "organicAgencies" },
            name: { kind: "Name", value: "page" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "host" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "host" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "locale" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "path" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagesAgencies" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sections" },
                            value: {
                              kind: "ListValue",
                              values: [{ kind: "EnumValue", value: "ORGANIC" }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "section" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "agency" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "AgencyCardData",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AgencyCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Agency" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "logoUrl" } },
          { kind: "Field", name: { kind: "Name", value: "sponsored" } },
          { kind: "Field", name: { kind: "Name", value: "externalId" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "services" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "3" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expertise" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "awards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviews" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "30" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "recommended" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRecentlyRecommended" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopPaidAgenciesQuery,
  TopPaidAgenciesQueryVariables
>;
